import React from 'react';

import { ModalProvider } from './src/providers/ModalProvider';
import { OverflowProvider } from './src/providers/OverflowProvider';

export const wrapRootElement = ({ element }) => (
  <ModalProvider>
    <OverflowProvider>
      {element}
    </OverflowProvider>
  </ModalProvider>
)

//Force browser to scroll to top on page change
export const shouldUpdateScroll = () => {
  window.history.scrollRestoration = "manual";
  window.setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0)

  //return false;
}