import React, { useState } from 'react'

import ModalContext from '../context/ModalContext';

export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [actionUrl, setActionUrl] = useState();
  const [actionType, setActionType] = useState();

  const handleToggleModal = (type, url) => {
    if(showModal){
      setActionUrl()
      setActionType()  
      setShowModal(false)
    }else{
      setActionUrl(url);
      setActionType(type);
      setShowModal(true)
    }
  }

  return (
    <ModalContext.Provider
      value={{ 
        handleToggleModal, 
        showModal, 
        setActionType, 
        actionType, 
        setActionUrl, 
        actionUrl         
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
